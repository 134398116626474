@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-sm font-sans antialiased;
}

.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}

input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
}

input:checked + .toggle-bg {
    @apply bg-green-600 border-green-600;
}

.fade-in {
    transition: opacity 1s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}
